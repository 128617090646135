/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

body {
  * {
    @supports (scrollbar-width: thin) {
      scrollbar-width: thin !important;
    }
    &::-webkit-scrollbar {
      width: 10px !important;
      height: 10px !important;
    }
    &::-webkit-scrollbar-button {
      width: 0px !important;
      height: 0px !important;
    }
    &::-webkit-scrollbar-thumb {
      background: #ccc !important;
      border: 0px none #fefeff !important;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #ccc !important;
    }
    &::-webkit-scrollbar-thumb:active {
      background: #ccc !important;
    }
    &::-webkit-scrollbar-track {
      background: #eee !important;
      border: 0px none #eee !important;
    }
    &::-webkit-scrollbar-track:hover {
      background: #eee !important;
    }
    &::-webkit-scrollbar-track:active {
      background: #eee !important;
    }
    &::-webkit-scrollbar-corner {
      background: transparent !important;
    }
  }
  .fullscreen_loader {
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    display: flex;
    .loader {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border-top: 3px solid;
      border-bottom: 3px solid;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      animation: spin 750ms ease-in-out infinite both;
    }
  }

  &.dark-layout {
    * {
      @supports (scrollbar-width: thin) {
        scrollbar-width: thin !important;
      }
      &::-webkit-scrollbar {
        width: 10px !important;
        height: 10px !important;
      }
      &::-webkit-scrollbar-button {
        width: 0px !important;
        height: 0px !important;
      }
      &::-webkit-scrollbar-thumb {
        background: #7b8499 !important;
        border: 0px none #fefeff !important;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #7b8499 !important;
      }
      &::-webkit-scrollbar-thumb:active {
        background: #7b8499 !important;
      }
      &::-webkit-scrollbar-track {
        background: #3b4253 !important;
        border: 0px none #3b4253 !important;
      }
      &::-webkit-scrollbar-track:hover {
        background: #3b4253 !important;
      }
      &::-webkit-scrollbar-track:active {
        background: #3b4253 !important;
      }
      &::-webkit-scrollbar-corner {
        background: transparent !important;
      }
    }
    .fullscreen_loader {
      background-color: #343d55;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
